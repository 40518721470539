<template>
  <div v-if="vendorsMap.length" :style="{backgroundColor: vendor.color_hex}" class="vendor-badge">
    {{ vendor.name[0] }}
  </div>
</template>
<script>
import Vue from 'vue'
import {mapGetters} from "vuex"

export default Vue.extend({
  props: ['vendorId'],
  computed: {
    ...mapGetters('enums', ['vendorsMap']),
    vendor: function () {
      return this.vendorsMap[this.vendorId]
    }
  }
})
</script>
<style lang="scss">
.navbar .new-venue-bottom{
  padding: 0.5rem 50px 0.5rem 0.5rem;
}

</style>
<style lang="scss" scoped>
.vendor-badge {
  --size: 14px;

  display: inline-block;
  color: white;
  border-radius: 3px;
  width: var(--size);
  height: var(--size);
  line-height: var(--size);
  text-align: center;
  font-size: calc(var(--size)*0.8);
  text-transform: uppercase;
  position: relative;
  top: -2px;
}
</style>
