<template>
  <div>
    <div class="container with-footer mt-5">
      <div class="row">
        <div class="col-sm">
          <b-card class="card" header="Všeobecné" v-if="user.settings">

            <strong class="card-title">Kontakt na obchodníka</strong>
            <template v-for="(contact, i) in user.settings.contacts">
              <b-form-group :key="i" :label-for="'vendor' + contact.vendor_id">
                <template #label>
                   <VendorBadge :vendorId="contact.vendor_id" /> {{ vendorsMapNames[contact.vendor_id] }}
                </template>
                <b-form-input :id="'vendor' + contact.vendor_id" v-model="contact.email" type="email" :state="validateVendorEmailState(i)"/>
                <b-form-invalid-feedback>Toto pole musí obsahovať emailovú adresu.</b-form-invalid-feedback>
              </b-form-group>
            </template>

            <strong class="card-title">Zobrazovanie cien</strong>
            <b-form-checkbox class="show_prices" v-model="user.settings.is_vat_included">
              Zobrazovať ceny s DPH
            </b-form-checkbox>

          </b-card>
        </div>
        <div class="col-sm">
          <b-card class="card" header="Spoločnosť" v-if="user.company">

            <strong class="card-title">Fakturačné údaje</strong>

            <template v-for="(field, i) in companyFields">
              <b-form-group :label="field.title" :key="i" :label-for="field.name">
                <b-form-input :id="'company' + field.name" :value="user.company[field.name]" @input="fillCompanyField($event, field.name)" type="text" :state="validateState(field.name, 'company')" />
                <b-form-invalid-feedback>{{field.warning}}</b-form-invalid-feedback>
              </b-form-group>
            </template>

          </b-card>
        </div>
        <div class="col-sm">
          <b-card class="card" header="Účet" v-if="user">
            <strong class="card-title">Prihlasovacie údaje</strong>

            <b-form-group label="Meno" label-for="name">
              <b-form-input id="name" v-model="$v.user.name.$model" type="text" :state="validateState('name')"/>
              <b-form-invalid-feedback>Toto pole je povinné a musí obsahovať aspoň 3 znaky.</b-form-invalid-feedback>
            </b-form-group>

            <b-form-group label="Priezvisko" label-for="surname">
              <b-form-input id="surname" v-model="$v.user.surname.$model" type="text" :state="validateState('surname')"/>
              <b-form-invalid-feedback>Toto pole je povinné a musí obsahovať aspoň 3 znaky.</b-form-invalid-feedback>
            </b-form-group>

            <b-form-group label="Email" label-for="email">
              <b-form-input id="email" v-model="$v.user.email.$model" type="text" :state="validateState('email')"/>
              <b-form-invalid-feedback>Toto pole je povinné a musí obsahovať emailovú adresu.</b-form-invalid-feedback>
            </b-form-group>

          </b-card>

          <b-card class="card mt-4" header="Zmena hesla">
            <b-form-group label="Nové heslo" label-for="password">
              <b-form-input id="password" v-model="$v.password.$model" type="password" :state="validatePasswordState('password')"/>
              <b-form-invalid-feedback>Heslo musí obsahovať aspoň 8 znakov.</b-form-invalid-feedback>
            </b-form-group>

            <b-form-group label="Potvrdenie hesla" label-for="password_confirmation">
              <b-form-input id="password_confirmation" v-model="$v.password_confirmation.$model" type="password" :state="validatePasswordState('password_confirmation')"/>
              <b-form-invalid-feedback>Heslá sa nezhodujú.</b-form-invalid-feedback>
            </b-form-group>

          </b-card>
        </div>
      </div>
    </div>
    <SubmitFooter v-if="!demoDomain" :on-submit="onSubmit" />
  </div>
</template>
<script>
  import { mapGetters, mapActions } from 'vuex'
  import { required, email, minLength, sameAs } from "vuelidate/lib/validators"
  import VendorBadge from "@/plugins/app@enums/vendor-badge"
  import SubmitFooter from "@/plugins/app/_layouts/components/submit-footer"
  import load from "@/plugins/app/load"
  export default {
    components: {SubmitFooter, VendorBadge},
    data() {
      return {
        companyFields: [
          { name: 'name', title: 'Názov spoločnosti', warning: 'Toto pole je povinné a musí obsahovať aspoň 3 znaky.'},
          { name: 'ico', title: 'IČO', warning: ''},
          { name: 'dic', title: 'DIČ/IČ DPH', warning: ''},
          { name: 'street', title: 'Ulica', warning: ''},
          { name: 'city', title: 'Mesto', warning: ''},
          { name: 'zip', title: 'PSČ', warning: ''},
          { name: 'country', title: 'Krajina', warning: ''},
        ],
        password: '',
        password_confirmation: ''
      }
    },
    validations: {
      user: {
        name: {required},
        surname: {required},
        email: {required, email},
        company: {
          name: {required},
          ico: {},
          dic: {},
          street: {},
          city: {},
          zip: {},
          country: {}
        },
        settings: {
          contacts: {
            $each: {
              email: {email}
            }
          }
        },
      },
      password: {
        minLength: minLength(8)
      },
      password_confirmation: {
        sameAsPassword: sameAs('password')
      }
    },
    async mounted() {
      await load.loadAllData()
      this._prefillMissingFields()
    },
    watch: {
      vendors: function () {
        this._prefillMissingFields()
      }
    },
    computed: {
      ...mapGetters('wAuth', ['user']),
      ...mapGetters('enums', ['vendors', 'vendorsMapNames']),
      demoDomain() {
        return process.env.VUE_APP_DEMO_DOMAIN === document.location.host
      }
    },
    methods: {
      ...mapActions('wAuth', ['updateUserInfo', 'changePassword']),
      ...mapActions('cart', ['loadCart']),

      onSubmit() {
        this._passwordChange()

        this.$v.user.$touch()
        if (this.$v.user.$anyError)
          return

        this.$v.user.$reset()
        this.$wToast.clear_loading()
        this.updateUserInfo(this.user).then( async _ => {
          await this.loadCart()
          this.$wToast.success('Zmeny boli uložené')
        }).catch(e => this.$wToast.error(e))
      },

      _passwordChange() {
        if(!this.password)
          return

        this.$v.password.$touch()
        this.$v.password_confirmation.$touch()
        if(this.$v.password.$anyError || this.$v.password_confirmation.$anyError)
          return

        this.$v.password.$reset()
        this.$v.password_confirmation.$reset()
        this.$wToast.clear_loading()
        this.changePassword({
          password: this.password,
          password_confirmation: this.password_confirmation,
        }).then( _ => {
          this.$wToast.success('Heslo bolo zmenené')
        }).catch(e => this.$wToast.error(e))
      },

      validateState(field, type) {
        const { $dirty, $error } = type ? this.$v.user[type][field] : this.$v.user[field]
        return $dirty ? !$error : null
      },

      validatePasswordState(field) {
        const { $dirty, $error } = this.$v[field]
        return $dirty ? !$error : null
      },

      validateVendorEmailState(i) {
        const { $dirty, $error, $model } = this.$v.user.settings.contacts.$each[i]

        if($model.email)
          return $dirty ? !$error : null

        return null
      },


      fillCompanyField($e, field) {
        this.user.company[field] = $e
        this.$v.user.company[field].$touch()
        this.$forceUpdate()
      },

      _prefillMissingFields() {
        if(!this.user.settings) {
          this.user.settings = {
            contacts: [],
            is_vat_included: true
          }

          this._prefillContacts()
        } else
        if(!this.user.settings.contacts.length)
          this._prefillContacts()

      },
      _prefillContacts() {
        this.vendors.forEach(v => {
          this.user.settings.contacts.push({
            email: "",
            vendor_id: v.id
          })
        })
      },
    }
  }
</script>
