<template>
  <div class="footer">
    <div class="container">
      <slot></slot>
      <b-button variant="success" @click="onSubmit">{{ submitText || 'Uložiť' }}</b-button>
    </div>
  </div>
</template>

<script>
import Vue from "vue"

export default Vue.extend({
  name: 'submit-footer',
  props: ['onSubmit', 'submitText']
})
</script>

<style lang="scss" scoped>
  .footer {
    background: white;
    box-shadow: 0 -1px 0 #EFEFEF;
    padding: 10px 0;
    z-index: 999;
    position: fixed;
    bottom: 0;
    right: 0;
    left: 0;
    text-align: right;
  }
</style>
<style lang="scss">
  .with-footer {
    padding-bottom: 100px;
  }
</style>